import React from 'react';
import { Title } from '@web-passion/uikit';

import {
  TitleColour,
  TitleSpacing,
  Bold,
  h3Size,
  LineHeight,
} from '../../utils/variables';

export default function TitleH3(props) {
  const { colour, margin, line, size, style, className, children, ...other } =
    props;
  return (
    <Title
      tag="h3"
      className={className}
      style={{
        ...style,
        color: colour || TitleColour,
        marginBottom: margin || TitleSpacing,
        lineHeight: line || LineHeight,
        fontSize: size || h3Size,
      }}
      {...other}
    >
      {children}
    </Title>
  );
}
